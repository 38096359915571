import {header, HttpError, RequestBuilder, toHttpTask} from 'ajaxian';
import Decoder from 'jsonous';
import Task from 'taskarian';
import {assertNever} from '../Assertions';
import {
  partialItemDecoder,
  thumbnailMetadatasDecoder,
} from '../ItemStore/Decoders';
import {PartialItem, ThumbnailMetadata} from '../ItemStore/Types';
import {warn} from '../Logging';
import {wikiPageRelatedDecoder} from '../MapStore/Decoders';
import {searchResultsDecoder} from './Decoders';
import {SearchResults} from './Types';

const restBaseUrl = 'https://en.wikipedia.org/api/rest_v1';

const phpBaseUrl = 'https://en.wikipedia.org/w/api.php';

const imageArgs = {
  action: 'query',
  format: 'json',
  prop: 'imageinfo',
  iiprop: 'timestamp|user|canonicaltitle|extmetadata|url|badfile',
  origin: '*',
};

const imageTitleFromUrl = (url: URL): string =>
  'File:' +
  url.pathname
    .split('/')
    .filter(segment => !segment.match(/^\d+px-/))
    .reverse()[0]
    .replace(/_/g, ' ');

const request = <T>(
  link: string,
  decoder: Decoder<T>,
  payload?: {},
): RequestBuilder<T> =>
  new RequestBuilder<T>({
    url: link,
    decoder: decoder.toJsonFn(),
    method: 'get',
    timeout: 0,
    data: payload,
    withCredentials: false,
    headers: [header('Api-User-Agent', 'spensterc@yahoo.com')],
  });

const callApi = <T>(decoder: Decoder<T>, payload?: {}) => (
  link: string,
): Task<HttpError, T> => toHttpTask(request(link, decoder, payload));

const cleanId = (id: string) =>
  encodeURI(id.replace(/ /g, '_'))
    .replace(/\//g, '%2F')
    .replace(/\?/g, '%3F');

const urlQuery = (obj: {[k: string]: string}): string =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');

const urlSearchParams = (search: string) =>
  urlQuery({
    action: 'opensearch',
    limit: '10',
    namespace: '0',
    format: 'json',
    redirects: 'resolve',
    origin: '*',
    search: encodeURIComponent(search),
  });

export const handleHttpError = (err: HttpError) => {
  switch (err.kind) {
    case 'timeout':
    case 'network-error':
      warn(`[${err.kind}]`);
      break;
    case 'bad-status':
      warn(`[${err.kind}]: ${err.response.status}`);
      break;
    case 'bad-payload':
    case 'bad-url':
      warn(`[${err.kind}]: ${err.message}`);
      break;
    default:
      assertNever(err);
  }
};

export const fetchSummaryT = (id: string): Task<HttpError, PartialItem> =>
  callApi(partialItemDecoder)(`${restBaseUrl}/page/summary/${cleanId(id)}`).map<
    PartialItem
  >(summary => ({
    ...summary,
    title: summary.title.replace(/_/g, ' '),
  }));

export const fetchImageT = (url: URL): Task<HttpError, ThumbnailMetadata> => {
  const title = imageTitleFromUrl(url).replace(/_/g, ' ');
  const query = urlQuery({...imageArgs, titles: title});
  return callApi(thumbnailMetadatasDecoder)(`${phpBaseUrl}?${query}`).map(
    metadatas => metadatas[0],
  );
};

export const fetchRelatedT = (id: string): Task<HttpError, PartialItem[]> =>
  callApi(wikiPageRelatedDecoder)(
    `${restBaseUrl}/page/related/${cleanId(id)}`,
  ).map(summaries =>
    summaries.map(summary => ({
      ...summary,
      title: summary.title.replace(/_/g, ' '),
    })),
  );

export const fetchSearch = (
  search: string,
  fn: (results: SearchResults) => void,
) =>
  callApi(searchResultsDecoder)(
    `${phpBaseUrl}?${urlSearchParams(search)}`,
  ).fork(handleHttpError, fn);
