import * as React from 'react';

interface Props {
  title?: string;
}

const baseTitle = 'Wikm: Wikipedia Map';

class Title extends React.Component<Props, {}> {
  get fullTitle() {
    if (this.props.title) {
      return `${this.props.title} - ${baseTitle}`;
    } else {
      return baseTitle;
    }
  }

  componentDidMount() {
    document.title = this.fullTitle;
  }

  componentDidUpdate() {
    document.title = this.fullTitle;
  }

  componentWillUnmount() {
    document.title = baseTitle;
  }

  render() {
    return null;
  }
}

export default Title;
