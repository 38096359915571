import {Slider, withStyles} from '@material-ui/core';
import React from 'react';
import StyleClasses from '../../StyleClasses';

const styles = () => ({
  root: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  slider: {
    padding: '22px 0px',
  },
});

interface Props {
  classes: StyleClasses<typeof styles>;
  connections: number;
  setConnections: (num: number) => void;
}

const Settings: React.SFC<Props> = ({classes, connections, setConnections}) => (
  <div className={classes.root}>
    <Slider
      value={connections}
      className={classes.slider}
      min={1}
      max={10}
      step={1}
      onChange={(_, val) => {
        val = typeof val === 'number' ? val : val[0];
        setConnections(val);
      }}
    />
  </div>
);

export default withStyles(styles, {withTheme: true})(Settings);
