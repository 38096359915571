import {Theme, withStyles} from '@material-ui/core';
import {just, Maybe, nothing} from 'maybeasy';
import React from 'react';
import ItemStore, {allItemStores} from '../../ItemStore';
import mapStore from '../../MapStore';
import {NodeGraph} from '../../MapStore/Types';
import Title from '../../Title';
import {handleHttpError} from '../../WikiQuery';
import Display from './display';

const styles = (theme: Theme) => ({
  '@global': {
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
      },
    },
  },
});

interface Props {}

interface State {
  center?: string;
  connections: number;
  graph?: NodeGraph;
  itemStore?: ItemStore;
  infoTitle?: string;
  loading: boolean;
  wiHistory: string[];
}

const emptyState = {
  center: undefined,
  graph: undefined,
  itemStore: undefined,
  infoTitle: undefined,
  loading: false,
};

function fromLocalStorage<T>(name: string, def: T): T {
  const json = localStorage.getItem(name);
  return json ? JSON.parse(json) : def;
}

class Items extends React.Component<Props, State> {
  private wiHistoryMaxLength = 100;

  constructor(props: Props) {
    super(props);

    const connections = fromLocalStorage('connections', 5);
    const wiHistory = fromLocalStorage('wiHistory', []);

    this.state = {
      connections,
      loading: false,
      wiHistory,
    };
  }

  setCenter = (center: string) => {
    this.setInfoTitle(center);
    this.setState({
      center: center,
      loading: true,
      wiHistory: this.newWiHistory(center),
    });
    mapStore
      .withGraphT(center, this.state.connections, this.state.connections)
      .fork(handleHttpError, graph => this.setState({graph, loading: false}));
  };

  setConnections = (num: number) => {
    if (num >= 1 && num <= 20) {
      localStorage.setItem('connections', JSON.stringify(num));
      this.setState({connections: num});
      if (this.state.center) {
        mapStore
          .withGraphT(this.state.center, num, num)
          .fork(handleHttpError, graph => this.setState({graph}));
      }
    }
  };

  setInfoTitle = (title: string) => {
    if (title !== this.state.infoTitle) {
      this.setState({infoTitle: title});
      this.setState({itemStore: allItemStores.fetch(title)});
    }
  };

  pageTitle = (): Maybe<string> => {
    const path = window.location.pathname;
    if (path.match(/^\/s\//)) {
      return just(path.slice(3).replace(/_/g, ' '));
    } else {
      return nothing();
    }
  };

  componentDidMount() {
    this.pageTitle().do(this.setCenter);

    window.addEventListener('popstate', e => {
      const center = e.state;
      if (!center) {
        this.setState(emptyState);
      } else {
        this.setState({wiHistory: this.newWiHistory(e.state)});
        this.setCenter(center);
      }
    });
  }

  newWiHistory = (center: string) => {
    const wiHistory = this.state.wiHistory;
    if (!center) return wiHistory;

    const index = wiHistory.indexOf(center);
    if (~index) wiHistory.splice(index, 1);
    if (wiHistory.length >= this.wiHistoryMaxLength) wiHistory.pop();
    wiHistory.unshift(center);
    localStorage.setItem('wiHistory', JSON.stringify(wiHistory));
    return wiHistory;
  };

  navToMap = (center: string) => {
    if (center === this.state.center) {
      return;
    }

    window.history.pushState(
      center,
      center,
      `/s/${center.split(' ').join('_')}`,
    );

    this.setCenter(center);
  };

  goHome = (event: {preventDefault: () => void}) => {
    event.preventDefault();
    if (window.location.pathname !== '/') {
      this.setState(emptyState);
      window.history.pushState('', '', '/');
    }
  };

  render() {
    return (
      <div className="items-container">
        <Title title={this.state.center} />
        <Display
          center={this.state.center}
          connections={this.state.connections}
          goHome={this.goHome}
          graph={this.state.graph}
          itemStore={this.state.itemStore}
          loading={this.state.loading}
          navToMap={this.navToMap}
          setConnections={this.setConnections}
          setInfoTitle={this.setInfoTitle}
          wiHistory={this.state.wiHistory}
        />
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Items);
