import {fromNullable, just, nothing} from 'maybeasy';
import {IReactionPublic} from 'mobx';
import ItemStore from '.';
import {assertNever} from '../Assertions';
import {warn} from '../Logging';
import {fetchImageT, fetchSummaryT, handleHttpError} from '../WikiQuery';
import {itemAndChildrenDecoder} from './Decoders';
import {FullItem, State} from './Types';

// Convert all URL objects to just their href strings, so they can be JSON stringified.
const jsonPrep = (item: FullItem) => ({
  ...item,
  thumbnail: item.thumbnail.map(thumbnail => ({
    ...thumbnail,
    source: thumbnail.source.href,
    metadata: {
      ...thumbnail.metadata,
      descriptionUrl: thumbnail.metadata.descriptionUrl.href,
      licenseUrl: thumbnail.metadata.licenseUrl.map(url => url.href),
    },
  })),
});

export const reactions = (store: ItemStore) => (
  state: State,
  r: IReactionPublic,
): void => {
  switch (state.kind) {
    case 'waiting':
      break;
    case 'loading-locally':
      fromNullable(localStorage.getItem(state.title))
        .map(json => itemAndChildrenDecoder.decodeJson(json))
        .map<void>(task =>
          task.cata({
            Ok: ({item, children}) => store.ready(item, children),
            Err: e => {
              warn(`Couldn't parse local cache of '${state.title}': #${e}`);
              store.loadingRemotely();
            },
          }),
        )
        .getOrElse(store.loadingRemotely);
      break;
    case 'loading-remotely':
      fetchSummaryT(state.title).fork(e => {
        store.error(`Couldn't fetch summary '${state.title}': ${e}`);
        handleHttpError(e);
      }, store.loadingThumbnail);
      break;
    case 'loading-thumbnail':
      state.item.thumbnail
        .map<void>(thumbnail => {
          fetchImageT(thumbnail.source).fork(
            e => {
              warn(`Couldn't fetch metadata for item '${state.title}': ${e}`);
              handleHttpError(e);
              store.ready({
                ...state.item,
                thumbnail: nothing(),
              });
            },
            metadata => {
              store.ready({
                ...state.item,
                thumbnail: just({
                  ...thumbnail,
                  metadata,
                }),
              });
            },
          );
        })
        .getOrElse(() => {
          store.ready({
            ...state.item,
            thumbnail: nothing(),
          });
        });
      break;
    case 'ready':
      localStorage.setItem(
        state.title,
        JSON.stringify({item: jsonPrep(state.item), children: state.children}),
      );
      break;
    case 'setting-children':
      store.ready(state.item);
      break;
    case 'error':
      console.error(`[WIKM] [${state.store}] ${state.message}`);
      r.dispose();
      break;
    default:
      assertNever(state);
  }
};
