import {ListItem, ListItemText} from '@material-ui/core';
import React from 'react';

interface Props {
  className?: string;
  closeDrawer: () => void;
  navToMap: (title: string) => void;
  title: string;
}

const Suggestion: React.SFC<Props> = ({
  className,
  closeDrawer,
  navToMap,
  title,
}) => {
  const clicked = () => {
    closeDrawer();
    navToMap(title);
  };

  return (
    <ListItem button dense className={className}>
      <ListItemText primary={title} onClick={clicked} />
    </ListItem>
  );
};

export default Suggestion;
