import {CssBaseline} from '@material-ui/core';
import {observer} from 'mobx-react';
import React from 'react';
import Items from '../Items';

const App: React.SFC = () => (
  <>
    <CssBaseline />
    <Items />
  </>
);

export default observer(App);
