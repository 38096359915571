import {reaction} from 'mobx';
import {IReactionPublic} from 'mobx';

interface Stateful {
  state: unknown;
}

type StoreReaction<A extends Stateful> = (
  store: A,
) => (state: A['state'], r: IReactionPublic) => void;

export const addReactions = <A extends Stateful>(
  store: A,
  reactions: StoreReaction<A>,
): void => {
  reaction(() => store.state, reactions(store), {fireImmediately: true});
};
