import {just, Maybe, nothing} from 'maybeasy';

export const always = <A>(a: A) => (): A => a;

export const find = <T>(
  fn: (t: T) => boolean,
  ts: ReadonlyArray<T>,
): Maybe<T> => {
  const obj = ts.find(fn);
  return typeof obj === 'undefined' ? nothing<T>() : just(obj);
};

export const byProp = <T>(props: Partial<T>) => (thing: T): boolean =>
  Object.keys(props).every(
    key => thing[key as keyof T] === props[key as keyof T],
  );

export const mapMaybe = <A, B>(
  fn: (a: A) => Maybe<B>,
  ts: ReadonlyArray<A>,
): B[] =>
  ts.reduce(
    (accum, t) =>
      fn(t).cata({
        Just: value => accum.concat([value]),
        Nothing: always(accum),
      }),
    [] as B[],
  );
