import Decoder, {
  array,
  at,
  date,
  field,
  maybe,
  number,
  oneOf,
  string,
  succeed,
} from 'jsonous';
import {just, Maybe, nothing} from 'maybeasy';
import {err, ok} from 'resulty';
import {
  Coordinates,
  FullItem,
  FullThumbnail,
  PartialItem,
  PartialThumbnail,
  ThumbnailMetadata,
  ThumbnailMetadatas,
} from './Types';

export const url: Decoder<URL> = new Decoder<URL>(value => {
  try {
    const url = new URL(value);
    return ok(url);
  } catch (error) {
    return err(`Expected URL but got ${JSON.stringify(value)}`);
  }
});

export const justDecoder = <A>(decoder: Decoder<A>): Decoder<Maybe<A>> =>
  field('value', decoder).map(just);

export const nothingDecoder = <A>(): Decoder<Maybe<A>> =>
  new Decoder<Maybe<A>>(v => {
    return Object.keys(v).length === 0
      ? ok(nothing<A>())
      : err(`Expected {} but got ${JSON.stringify(v)}`);
  });

export const maybeDecoder = <A>(decoder: Decoder<A>): Decoder<Maybe<A>> =>
  oneOf([justDecoder(decoder), nothingDecoder()]);

export const partialThumbnailDecoder: Decoder<PartialThumbnail> = succeed({
  metadata: nothing<ThumbnailMetadata>(),
})
  .assign('source', field('source', url))
  .assign('width', field('width', number))
  .assign('height', field('height', number));

const metadata = (category: string): Array<string | number> => [
  'imageinfo',
  0,
  'extmetadata',
  category,
  'value',
];

export const thumbnailMetadataDecoder: Decoder<ThumbnailMetadata> = succeed({})
  .assign('title', field('title', string))
  .assign('descriptionUrl', at(['imageinfo', 0, 'descriptionurl'], url))
  .assign('timestamp', at(['imageinfo', 0, 'timestamp'], date))
  .assign('user', at(['imageinfo', 0, 'user'], string))
  .assign('description', maybe(at(metadata('ImageDescription'), string)))
  .assign('credit', maybe(at(metadata('Credit'), string)))
  .assign('artist', maybe(at(metadata('Artist'), string)))
  .assign('license', maybe(at(metadata('UsageTerms'), string)))
  .assign('shortLicense', maybe(at(metadata('LicenseShortName'), string)))
  .assign('licenseUrl', maybe(at(metadata('LicenseUrl'), url)));

export const localStorageThumbnailMetadataDecoder: Decoder<
  ThumbnailMetadata
> = succeed({})
  .assign('title', field('title', string))
  .assign('descriptionUrl', field('descriptionUrl', url))
  .assign('timestamp', field('timestamp', date))
  .assign('user', field('user', string))
  .assign('description', field('description', maybeDecoder(string)))
  .assign('description', field('description', maybeDecoder(string)))
  .assign('credit', field('credit', maybeDecoder(string)))
  .assign('artist', field('artist', maybeDecoder(string)))
  .assign('license', field('license', maybeDecoder(string)))
  .assign('shortLicense', field('shortLicense', maybeDecoder(string)))
  .assign('licenseUrl', field('licenseUrl', maybeDecoder(url)));

export const fullThumbnailDecoder: Decoder<FullThumbnail> = succeed({})
  .assign('metadata', field('metadata', localStorageThumbnailMetadataDecoder))
  .assign('source', field('source', url))
  .assign('width', field('width', number))
  .assign('height', field('height', number));

export const thumbnailMetadatasObjectDecoder: Decoder<
  ThumbnailMetadatas
> = new Decoder<ThumbnailMetadatas>(value => {
  if (!(value instanceof Object)) {
    return err<string, ThumbnailMetadatas>(
      `I expected an object but got ${JSON.stringify(value)}`,
    );
  }

  let result = ok<string, ThumbnailMetadatas>([]);

  Object.keys(value).every(key => {
    result = result
      .andThen(vs =>
        thumbnailMetadataDecoder.decodeAny(value[key]).map(v => vs.concat(v)),
      )
      .mapError(e => `Error in thumbnailMetadatasDecoder at key ${key}: ${e}`);
    return result.map(() => true).getOrElseValue(false);
  });

  return result;
});

export const thumbnailMetadatasDecoder: Decoder<ThumbnailMetadatas> = at(
  ['query', 'pages'],
  thumbnailMetadatasObjectDecoder,
);

export const coordinatesDecoder: Decoder<Coordinates> = succeed({})
  .assign('lat', field('lat', number))
  .assign('lon', field('lon', number));

export const partialItemDecoder: Decoder<PartialItem> = succeed({})
  .assign('title', field('title', string))
  .assign('displayTitle', maybe(field('displaytitle', string)))
  .assign('pageId', maybe(field('pageid', number)))
  .assign('extract', field('extract', string))
  .assign('extractHtml', maybe(field('extract_html', string)))
  .assign('thumbnail', maybe(field('thumbnail', partialThumbnailDecoder)))
  .assign('lang', field('lang', string))
  .assign('dir', field('dir', string))
  .assign('timestamp', maybe(field('timestamp', date)))
  .assign('description', maybe(field('description', string)))
  .assign('coordinates', maybe(field('coordinates', coordinatesDecoder)));

export const fullItemDecoder: Decoder<FullItem> = succeed({})
  .assign('title', field('title', string))
  .assign('extract', field('extract', string))
  .assign('lang', field('lang', string))
  .assign('dir', field('dir', string))
  .assign('coordinates', field('coordinates', maybeDecoder(coordinatesDecoder)))
  .assign('description', field('description', maybeDecoder(string)))
  .assign('displayTitle', field('displayTitle', maybeDecoder(string)))
  .assign('extractHtml', field('extractHtml', maybeDecoder(string)))
  .assign('pageId', field('pageId', maybeDecoder(number)))
  .assign('thumbnail', field('thumbnail', maybeDecoder(fullThumbnailDecoder)))
  .assign('timestamp', field('timestamp', maybeDecoder(date)));

export interface ItemAndChildren {
  item: FullItem;
  children: string[];
}

export const itemAndChildrenDecoder: Decoder<ItemAndChildren> = succeed({})
  .assign('item', field('item', fullItemDecoder))
  .assign('children', field('children', array(string)));
