import {
  ButtonBase,
  CardContent,
  Collapse,
  Divider,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import ItemStore from '../../ItemStore';
import StyleClasses from '../../StyleClasses';

const styles = (theme: Theme) => ({
  button: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  infoImage: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '25',
      width: '25',
    },
  },
  caption: {
    color: 'gray',
    display: 'block',
  },
});

interface Props {
  itemStore: ItemStore;
  classes: StyleClasses<typeof styles>;
}

interface State {
  expanded: boolean;
}

class WikiImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {expanded: false};
  }

  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  };

  render() {
    const {classes, itemStore} = this.props;
    return itemStore.thumbnail
      .map(thumbnail => (
        <>
          <ButtonBase
            className={classes.button}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Show more">
            <img
              className={classes.infoImage}
              src={thumbnail.source.href}
              alt="Show more"
            />
          </ButtonBase>
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {thumbnail.metadata.description
                .map(description => (
                  <>
                    <Typography
                      variant="caption"
                      dangerouslySetInnerHTML={{__html: description}}
                    />
                    <Divider />
                  </>
                ))
                .getOrElseValue(<></>)}
              <Typography variant="caption" className={classes.caption}>
                Date: {thumbnail.metadata.timestamp.toLocaleDateString()}
              </Typography>
              {thumbnail.metadata.artist
                .map(artist => (
                  <Typography
                    variant="caption"
                    className={classes.caption}
                    dangerouslySetInnerHTML={{__html: `Author: ${artist}`}}
                  />
                ))
                .getOrElse(() => (
                  <Typography variant="caption" className={classes.caption}>
                    Credit:{' '}
                    <a
                      href={`https://commons.wikimedia.org/wiki/User:${
                        thumbnail.metadata.user
                      }`}>
                      {thumbnail.metadata.user}
                    </a>
                  </Typography>
                ))}

              {thumbnail.metadata.shortLicense
                .orElse(() => thumbnail.metadata.license)
                .map((licenseText: string) =>
                  thumbnail.metadata.licenseUrl
                    .map(licenseUrl => (
                      <Typography variant="caption" className={classes.caption}>
                        License: <a href={licenseUrl.href}>{licenseText}</a>
                      </Typography>
                    ))
                    .getOrElse(() => (
                      <Typography variant="caption" className={classes.caption}>
                        License: {licenseText}
                      </Typography>
                    )),
                )
                .getOrElseValue(<></>)}
              <Typography variant="caption" className={classes.caption}>
                <a href={thumbnail.metadata.descriptionUrl.href}>
                  Open on Wikipedia
                </a>
              </Typography>
            </CardContent>
          </Collapse>
        </>
      ))
      .getOrElseValue(<></>);
  }
}

export default withStyles(styles, {withTheme: true})(WikiImage);
