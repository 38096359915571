import {ListItem, ListItemText} from '@material-ui/core';
import React from 'react';
import Suggestion from '../Suggestion';

interface Props {
  wiHistory: string[];
  navToMap: (center: string) => void;
  closeDrawer: () => void;
}

const History: React.SFC<Props> = ({wiHistory, navToMap, closeDrawer}) => (
  <div className="history grid-element">
    <ListItem>
      <ListItemText primary="History" />
    </ListItem>
    {wiHistory.map(h => (
      <Suggestion
        key={h}
        title={h}
        navToMap={navToMap}
        closeDrawer={closeDrawer}
      />
    ))}
  </div>
);

export default History;
