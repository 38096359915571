import {InputAdornment, InputBase, Theme, withStyles} from '@material-ui/core';
import {Clear, Search} from '@material-ui/icons';
import React from 'react';
import debounce from '../../Debounce';
import StyleClasses from '../../StyleClasses';
import {fetchSearch} from '../../WikiQuery';
import {SearchResults} from '../../WikiQuery/Types';
import ClickOutside from '../ClickOutside';
import Suggestion from '../Suggestion';

const styles = (theme: Theme) => ({
  container: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
  },
  input: {
    color: 'white',
    fontSize: theme.typography.h6.fontSize,
    paddingLeft: 7,
    paddingRight: 7,
  },
  endAdornment: {
    marginLeft: 0,
  },
  suggestion: {
    marginLeft: 16,
  },
});

interface Props {
  classes: StyleClasses<typeof styles>;
  closeDrawer: () => void;
  navToMap: (center: string) => void;
  title: string;
}

interface State {
  results?: SearchResults;
  searchTerm: string;
  searching: boolean;
  hovering: boolean;
}

class SearchBar extends React.Component<Props, State> {
  private textField: HTMLInputElement | null = null;
  private clearingContents = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: this.props.title,
      searching: false,
      hovering: false,
    };
  }

  performSearch = debounce((searchTerm: string) => {
    if (searchTerm === '') {
      return this.setState({results: undefined});
    }

    fetchSearch(searchTerm, results => this.setState({results}));
  }, 250);

  handleChange = (searchTerm: string) => {
    this.setState({searchTerm, searching: true});
    this.performSearch(searchTerm);
  };

  onFocus = () => {
    if (this.clearingContents) return;
    this.setState({
      searching: true,
      searchTerm: this.props.title,
      results: undefined,
    });
  };

  clearContents = () => {
    this.clearingContents = true;
    this.setState({searchTerm: '', searching: true, results: undefined});
    this.textField && this.textField.focus();
    setTimeout(() => {
      this.clearingContents = false;
    }, 200);
  };

  onBlur = () => {
    if (this.clearingContents) return;
    this.setState({searching: false});
  };

  clickOutside = () => {
    this.textField && this.textField.blur();
  };

  render() {
    return (
      <ClickOutside
        onClickOutside={this.clickOutside}
        className={this.props.classes.container}>
        <InputBase
          value={
            this.state.searching ? this.state.searchTerm : this.props.title
          }
          inputRef={r => (this.textField = r)}
          className={this.props.classes.input}
          placeholder="Search Wikipedia"
          fullWidth
          onChange={e => this.handleChange(e.target.value)}
          onMouseEnter={() => this.setState({hovering: true})}
          onMouseLeave={() => this.setState({hovering: false})}
          onFocus={this.onFocus}
          onBlur={() => setTimeout(this.onBlur, 10)}
          endAdornment={
            <InputAdornment
              position="end"
              className={this.props.classes.endAdornment}>
              {this.state.hovering || this.state.searching ? (
                <Clear onClick={this.clearContents} />
              ) : (
                <Search />
              )}
            </InputAdornment>
          }
        />
        {this.state.searching &&
          this.state.results &&
          this.state.results.titles.map(title => (
            <Suggestion
              key={title}
              title={title}
              navToMap={this.props.navToMap}
              closeDrawer={this.props.closeDrawer}
            />
          ))}
      </ClickOutside>
    );
  }
}

export default withStyles(styles, {withTheme: true})(SearchBar);
