import {Drawer, Theme, withStyles} from '@material-ui/core';
import React from 'react';
import {NodeGraph} from '../../MapStore/Types';
import {strLit} from '../../StrLit';
import StyleClasses from '../../StyleClasses';
import Info from '../Info';
import NavBar from '../NavBar';
import NavDrawer from '../NavDrawer';
import OnlyDesktop from '../OnlyDesktop';
import OnlyMobile from '../OnlyMobile';
import WiMap from '../WiMap';
import ItemStore from '../../ItemStore';

export const drawerWidth = 240;
const mobileInfoHeight = '40vh';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    zIndex: 1,
  },
  appFrame: {
    position: strLit('relative'),
    display: 'flex',
    width: '100%',
    height: '100vh',
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100vh',
  },
  drawerPaperMobile: {
    height: mobileInfoHeight,
  },
  navDrawer: {
    overflowY: strLit('inherit'),
    display: 'flex',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: 'calc(100vh - 48px)',
    marginTop: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      marginRight: drawerWidth,
      width: `calc(100 - ${2 * drawerWidth}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px)',
      marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${mobileInfoHeight} - 56px)`,
      marginTop: 56,
    },
  },
});

interface Props {
  center?: string;
  classes: StyleClasses<typeof styles>;
  connections: number;
  goHome: (event: React.MouseEvent<HTMLElement>) => void;
  graph?: NodeGraph;
  itemStore?: ItemStore;
  loading: boolean;
  navToMap: (center: string) => void;
  setConnections: (num: number) => void;
  setInfoTitle: (title: string) => void;
  wiHistory: string[];
}

interface State {
  mobileOpen: boolean;
}

class Display extends React.Component<Props, State> {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState({mobileOpen: !this.state.mobileOpen});
  };

  closeDrawer = () => {
    this.setState({mobileOpen: false});
  };

  goHome = (event: React.MouseEvent<HTMLElement>) => {
    this.props.goHome(event);
    this.closeDrawer();
  };

  render() {
    const {classes} = this.props;

    const titleText = this.props.center || '';

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <NavBar
            closeDrawer={this.closeDrawer}
            navToMap={this.props.navToMap}
            titleText={titleText}
            handleDrawerToggle={this.handleDrawerToggle}
          />

          <OnlyMobile>
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.mobileOpen}
              classes={{
                paper: `${classes.drawerPaper} ${classes.navDrawer}`,
              }}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}>
              <NavDrawer
                center={this.props.center}
                closeDrawer={this.closeDrawer}
                connections={this.props.connections}
                goHome={this.goHome}
                navToMap={this.props.navToMap}
                setConnections={this.props.setConnections}
                wiHistory={this.props.wiHistory}
              />
            </Drawer>

            <Drawer
              variant="persistent"
              anchor="bottom"
              open
              classes={{
                paper: classes.drawerPaperMobile,
              }}>
              <Info itemStore={this.props.itemStore} />
            </Drawer>
          </OnlyMobile>

          <OnlyDesktop>
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: `${classes.drawerPaper} ${classes.navDrawer}`,
              }}>
              <NavDrawer
                center={this.props.center}
                closeDrawer={this.closeDrawer}
                connections={this.props.connections}
                goHome={this.goHome}
                navToMap={this.props.navToMap}
                setConnections={this.props.setConnections}
                wiHistory={this.props.wiHistory}
              />
            </Drawer>

            <Drawer
              variant="permanent"
              anchor="right"
              open
              classes={{
                paper: classes.drawerPaper,
              }}>
              <Info itemStore={this.props.itemStore} />
            </Drawer>
          </OnlyDesktop>

          <main className={classes.content}>
            <WiMap
              loading={this.props.loading}
              graph={this.props.graph}
              setInfoTitle={this.props.setInfoTitle}
              navToMap={this.props.navToMap}
            />
          </main>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Display);
