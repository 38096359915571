import {
  AppBar,
  IconButton,
  Theme,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import React from 'react';
import {strLit} from '../../StrLit';
import StyleClasses from '../../StyleClasses';
import {drawerWidth} from '../Items/display';
import SearchBar from '../SearchBar';

const styles = (theme: Theme) => ({
  appBar: {
    position: strLit('absolute'),
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      marginRight: drawerWidth,
      width: `calc(100% - ${2 * drawerWidth}px)`,
    },
    zIndex: 1201, // Should expand over mobile info drawer when searching
  },
  navIconHide: {
    marginTop: 9,
    marginRight: 4,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    alignItems: 'flex-start',
  },
});

interface Props {
  classes: StyleClasses<typeof styles>;
  closeDrawer: () => void;
  navToMap: (center: string) => void;
  titleText: string;
  handleDrawerToggle: () => void;
}

const NavBar: React.SFC<Props> = ({
  classes,
  closeDrawer,
  handleDrawerToggle,
  navToMap,
  titleText,
}) => (
  <AppBar className={classes.appBar}>
    <Toolbar className={classes.toolbar}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        className={classes.navIconHide}>
        <Menu />
      </IconButton>
      <SearchBar
        closeDrawer={closeDrawer}
        navToMap={navToMap}
        title={titleText}
      />
    </Toolbar>
  </AppBar>
);

export default withStyles(styles, {withTheme: true})(NavBar);
