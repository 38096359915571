import {withStyles} from '@material-ui/core';
import {GridLoader} from 'halogenium';
import React from 'react';
import Graph from 'react-graph-vis';
import {NodeGraph} from '../../MapStore/Types';
import {strLit} from '../../StrLit';
import StyleClasses from '../../StyleClasses';

const styles = () => ({
  wiMap: {
    position: strLit('relative'),
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface Props {
  graph?: NodeGraph;
  classes: StyleClasses<typeof styles>;
  loading: boolean;
  navToMap: (center: string) => void;
  setInfoTitle: (title: string) => void;
}

class WiMap extends React.Component<Props, {}> {
  render() {
    const {classes} = this.props;

    if (this.props.loading) {
      return (
        <div className={classes.wiMap}>
          <GridLoader color="rgb(20, 90, 186)" size="16px" />
        </div>
      );
    }

    if (!this.props.graph) {
      return <></>;
    }

    const options = {
      autoResize: true,
      edges: {
        smooth: {
          type: 'continuous',
        },
      },
      interaction: {
        hoverConnectedEdges: true,
        hover: true,
      },
      layout: {
        hierarchical: false,
      },
      nodes: {
        shape: 'dot',
        size: 20,
        font: {
          face:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
      },
      physics: {
        solver: 'hierarchicalRepulsion',
      },
    };

    const events = {
      click: (event: {nodes: string[]}) => {
        const {nodes} = event;
        const node = nodes[0];
        if (node) {
          this.props.setInfoTitle(node);
        }
      },
      doubleClick: (event: {nodes: string[]}) => {
        const {nodes} = event;
        const node = nodes[0];
        if (node) {
          this.props.navToMap(node);
        }
      },
    };

    return (
      <div className={classes.wiMap}>
        <Graph graph={this.props.graph} options={options} events={events} />
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(WiMap);
