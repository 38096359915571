import {HttpError} from 'ajaxian';
import Task from 'taskarian';
import {byProp} from '../Collections';
import {allItemStores} from '../ItemStore';
import {Edge, newEdge, newPreNode, NodeGraph, PreNode} from './Types';

const hashCode = (s: string) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

const seededShuffle = <T>(...array: T[]) => (string: string): T[] => {
  let seed = hashCode(string);

  const random = () => {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  const ret = [...array];
  for (let i = ret.length - 1; i > 0; i--) {
    let j = Math.floor(random() * (i + 1)); // random index from 0 to i
    [ret[i], ret[j]] = [ret[j], ret[i]]; // swap elements
  }
  return ret;
};

const scrambledColors = seededShuffle(
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
);

class MapStore {
  private nodes: PreNode[] = [];

  private edges: Edge[] = [];

  private colors: string[] = [];

  private graph = (): NodeGraph => ({
    nodes: this.nodes.map(node => ({...node, color: this.colors[node.level]})),
    edges: [...this.edges],
  });

  private updateGraph = (level: number, parent: string | null) => (
    title: string,
  ) => {
    const node = this.nodes.find(byProp({id: title}));
    if (node) {
      if (node.level > level) {
        node.level = level;
      }
    } else {
      this.nodes.push(newPreNode(title, level));
    }

    if (parent && !this.edges.find(byProp({from: parent, to: title}))) {
      this.edges.push(newEdge(parent, title));
    }
  };

  private reset = (center: string) => {
    this.nodes = [];
    this.edges = [];
    this.colors = scrambledColors(center);
  };

  private limitUnseenItems = <A extends {title: string}>(
    count: number,
    items: ReadonlyArray<A>,
  ): ReadonlyArray<A> => {
    const nodeIds = this.nodes.map(node => node.id);

    const unseenItems = items.filter(item => !~nodeIds.indexOf(item.title));
    const seenItems = items.filter(item => ~nodeIds.indexOf(item.title));

    return seenItems.concat(unseenItems.slice(0, count));
  };

  withGraphT = (
    center: string,
    firstLevel: number,
    secondLevel: number,
  ): Task<HttpError, NodeGraph> => {
    this.reset(center);

    const root = allItemStores.fetch(center);
    this.updateGraph(0, null)(root.title);

    return allItemStores
      .fetchRelatedT(root.title)
      .andThen(itemStores => {
        const limitedItemStores = this.limitUnseenItems(firstLevel, itemStores);
        limitedItemStores
          .map(itemStore => itemStore.title)
          .forEach(this.updateGraph(1, center));

        return Task.all(
          limitedItemStores.map(itemStore =>
            allItemStores.fetchRelatedT(itemStore.title).do(itemStoreLeaves => {
              const limitedItemStores = this.limitUnseenItems(
                secondLevel,
                itemStoreLeaves,
              );
              limitedItemStores
                .map(itemStore => itemStore.title)
                .forEach(this.updateGraph(2, itemStore.title));
            }),
          ),
        );
      })
      .map(this.graph);
  };
}

const mapStore = new MapStore();

export default mapStore;
