import {observer} from 'mobx-react';
import {
  Card,
  CardContent,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import ItemStore from '../../ItemStore';
import StyleClasses from '../../StyleClasses';
import WikiImage from '../WikiImage';

const styles = (theme: Theme) => ({
  infoContainer: {
    padding: theme.spacing(1),
  },
  infoIframe: {
    border: 0,
    width: '100%',
    height: '100vh',
  },
});

interface Props {
  itemStore?: ItemStore;
  classes: StyleClasses<typeof styles>;
}

interface State {
  expanded: boolean;
}

@observer
class Info extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {expanded: false};
  }

  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  };

  render() {
    const {classes, itemStore} = this.props;

    const backup = (
      <div className={classes.infoContainer}>
        <Card>
          <CardContent>
            <Typography variant="h6">Welcome to Wikm!</Typography>
            <Typography variant="body1" component="div">
              <ol>
                <li>Search for a topic</li>
                <li>Click/tap a circle to see that topic's summary</li>
                <li>Double-click/tap a node to navigate the central topic</li>
                <li>Explore!</li>
              </ol>
            </Typography>
          </CardContent>
        </Card>
      </div>
    );

    if (!itemStore) {
      return backup;
    }

    return itemStore.item
      .map(item => {
        const body = item.extractHtml.getOrElseValue(item.extract);
        const title = item.title.split('?').join('%3F');
        const href = `https://en.wikipedia.org/wiki/${title}`;

        return (
          <div className={classes.infoContainer}>
            <Card>
              <WikiImage itemStore={itemStore} />
              <CardContent>
                {item.displayTitle
                  .map(html => (
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{__html: html}}
                    />
                  ))
                  .getOrElse(() => (
                    <Typography variant="h6">{item.title}</Typography>
                  ))}
                <Typography
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{__html: body}}
                />
                <Typography variant="body2" gutterBottom>
                  <a href={href}>Open on Wikipedia</a>
                </Typography>
                <Typography variant="caption">
                  Description written by{' '}
                  <a href="https://www.wikipedia.org/">Wikipedia</a> volunteers
                  and available under the{' '}
                  <a href="https://creativecommons.org/licenses/by-sa/4.0/">
                    CC BY-SA 4.0
                  </a>{' '}
                  license.
                </Typography>
              </CardContent>
            </Card>
          </div>
        );
      })
      .getOrElseValue(backup);
  }
}

export default withStyles(styles, {withTheme: true})(Info);
