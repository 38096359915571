type Fn<A extends unknown[]> = (...args: A) => void;

export default <A extends unknown[]>(fn: Fn<A>, delay: number): Fn<A> => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: A) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => fn(...args), delay);
  };
};
