import {strLit} from '../StrLit';

const error = (store: string, message: string) => ({
  kind: strLit('error'),
  store,
  message,
});

export type ErrorBase = ReturnType<typeof error>;

export const errorFn = <E extends ErrorBase, A = Omit<E, keyof ErrorBase>>(
  store: ErrorBase['store'],
) => (message: ErrorBase['message'], rest: A): A & ErrorBase => ({
  kind: 'error',
  store,
  message,
  ...rest,
});
