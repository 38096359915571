interface BaseNode {
  id: string;
  label: string;
}

export interface PreNode extends BaseNode {
  level: number;
}

export interface Node extends BaseNode {
  color: string;
}

export const newPreNode = (title: string, level: number): PreNode => ({
  level,
  id: title,
  label: title,
});

export interface Edge {
  id: string;
  from: string;
  to: string;
}

export const newEdge = (from: string, to: string): Edge => ({
  id: `${from}:${to}`,
  from,
  to,
});

export interface NodeGraph {
  nodes: ReadonlyArray<Node>;
  edges: ReadonlyArray<Edge>;
}

export type GraphFn = (graph: NodeGraph) => void;
