import React, {useEffect, useRef, useState} from 'react';

interface Props {
  onClickOutside: (event: Event) => void;
  className?: string;
}

const useListener = (event: string, fn: (e: Event) => void) =>
  useEffect(() => {
    document.addEventListener(event, fn);
    return () => document.removeEventListener(event, fn);
  }, [event, fn]);

const ClickOutside: React.SFC<Props> = ({
  onClickOutside,
  className,
  children,
}) => {
  const [isTouch, setIsTouch] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const effect = (e: Event) => {
    if (e.type === 'touchend') setIsTouch(true);
    if (e.type === 'click' && isTouch) return;
    const el = container.current;
    if (el && !el.contains(e.target as Node)) onClickOutside(e);
  };

  useListener('touchend', effect);
  useListener('click', effect);

  return (
    <div ref={container} className={className}>
      {children}
    </div>
  );
};

export default ClickOutside;
