import {Hidden} from '@material-ui/core';
import React from 'react';

const OnlyDesktop: React.SFC = ({children}) => (
  <Hidden smDown implementation="css">
    {children}
  </Hidden>
);

export default OnlyDesktop;
