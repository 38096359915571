import {HttpError} from 'ajaxian';
import {just, Maybe, nothing} from 'maybeasy';
import {action, computed, observable} from 'mobx';
import {fromArrayMaybe} from 'nonempty-list';
import Task from 'taskarian';
import {assertNever} from '../Assertions';
import {FullItem, FullThumbnail, PartialItem} from './Types';
import {addReactions} from '../Reactions';
import {fetchRelatedT} from '../WikiQuery';
import {reactions} from './Reactions';
import {
  error,
  loadingLocally,
  loadingRemotely,
  loadingThumbnail,
  ready,
  State,
  waiting,
  settingChildren,
} from './Types';

export default class ItemStore {
  @observable
  state: State;

  constructor(title: string) {
    this.state = waiting(title, []);
    addReactions(this, reactions);
  }

  @action
  loadingLocally = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
        this.state = loadingLocally(this.title, this.children);
        break;
      case 'loading-locally':
      case 'loading-remotely':
      case 'loading-thumbnail':
      case 'ready':
      case 'setting-children':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loadingRemotely = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading-locally':
        this.state = loadingRemotely(this.title, this.children);
        break;
      case 'loading-remotely':
      case 'loading-thumbnail':
      case 'ready':
      case 'setting-children':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loadingThumbnail = (item: PartialItem) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading-locally':
      case 'loading-remotely':
        this.state = loadingThumbnail(item, this.children);
        break;
      case 'loading-thumbnail':
      case 'ready':
      case 'setting-children':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (item: FullItem, children?: string[]) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading-locally':
      case 'loading-remotely':
      case 'loading-thumbnail':
      case 'ready':
      case 'setting-children':
        children = children || this.children;
        this.state = ready(item, children);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  settingChildren = (children: string[]) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading-locally':
      case 'loading-remotely':
      case 'loading-thumbnail':
        this.state.children = children;
        break;
      case 'ready':
        this.state = settingChildren(this.state.item, children);
        break;
      case 'setting-children':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: string) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading-locally':
      case 'loading-remotely':
      case 'loading-thumbnail':
      case 'setting-children':
        this.state = error(message, {
          title: this.title,
          children: this.children,
        });
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get item(): Maybe<PartialItem> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading-locally':
      case 'loading-remotely':
      case 'error':
        return nothing();
      case 'loading-thumbnail':
      case 'ready':
      case 'setting-children':
        return just(this.state.item);
    }
  }

  @computed
  get thumbnail(): Maybe<FullThumbnail> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading-locally':
      case 'loading-remotely':
      case 'error':
      case 'loading-thumbnail':
        return nothing();
      case 'ready':
      case 'setting-children':
        return this.state.item.thumbnail;
    }
  }

  @computed
  get title(): string {
    return this.state.title;
  }

  @computed
  get children(): string[] {
    return this.state.children;
  }
}

interface ItemsQueue {
  [key: string]: ItemStore[];
}

class AllItemStores {
  @observable
  itemsQueue: ItemsQueue = {};

  @action
  fetch = (title: string): ItemStore => {
    const itemStore = this.findOrInitialize(title);
    itemStore.loadingLocally();
    return itemStore;
  };

  @action
  fetchRelatedT = (title: string): Task<HttpError, ItemStore[]> =>
    fromArrayMaybe(this.fetch(title).children.map(this.fetch))
      .map(array => array.toArray())
      .map<Task<HttpError, ItemStore[]>>(Task.succeed)
      .getOrElse(() =>
        fetchRelatedT(title)
          .do(items =>
            this.fetch(title).settingChildren(items.map(item => item.title)),
          )
          .map(items =>
            items.map(item => {
              const itemStore = this.findOrInitialize(item.title);
              itemStore.loadingThumbnail(item);
              return itemStore;
            }),
          ),
      );

  private findOrInitialize = (title: string): ItemStore => {
    if (!this.itemsQueue[title]) {
      this.itemsQueue[title] = [];
    }
    const itemStores = this.itemsQueue[title];
    if (itemStores.length === 0) {
      itemStores.push(new ItemStore(title));
    }
    return itemStores[0];
  };
}

export const allItemStores = new AllItemStores();
