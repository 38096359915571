import {Divider, List, Theme, Typography, withStyles} from '@material-ui/core';
import React from 'react';
import {strLit} from '../../StrLit';
import StyleClasses from '../../StyleClasses';
import History from '../History';
import Settings from '../Settings';

const styles = (theme: Theme) => ({
  drawerHeader: {
    display: 'flex',
    flexDirection: strLit('column'),
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    ...theme.mixins.toolbar,
  },
  historyList: {
    overflow: 'auto',
    flexGrow: 1,
  },
});

interface Props {
  center?: string;
  classes: StyleClasses<typeof styles>;
  closeDrawer: () => void;
  connections: number;
  goHome: (event: React.MouseEvent<HTMLElement>) => void;
  navToMap: (center: string) => void;
  setConnections: (num: number) => void;
  wiHistory: string[];
}

const NavDrawer: React.SFC<Props> = ({
  center,
  classes,
  closeDrawer,
  connections,
  goHome,
  navToMap,
  setConnections,
  wiHistory,
}) => (
  <>
    <div className={classes.drawerHeader}>
      <Typography variant="h6" noWrap gutterBottom>
        <a href="/" onClick={goHome}>
          Wikm
        </a>
      </Typography>
      <Typography variant="caption">
        <a href="https://xkcd.com/214/">
          Wikipedia Map: Facilitate Fascination
        </a>
      </Typography>
    </div>
    <Divider />
    <List className={classes.historyList}>
      <History
        wiHistory={wiHistory}
        navToMap={navToMap}
        closeDrawer={closeDrawer}
      />
    </List>
    <Divider />
    {center && (
      <Settings connections={connections} setConnections={setConnections} />
    )}
  </>
);

export default withStyles(styles, {withTheme: true})(NavDrawer);
