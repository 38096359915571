import {Hidden} from '@material-ui/core';
import React from 'react';

const OnlyMobile: React.SFC = ({children}) => (
  <Hidden mdUp implementation="css">
    {children}
  </Hidden>
);

export default OnlyMobile;
