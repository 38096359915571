import {Maybe} from 'maybeasy';
import {ErrorBase, errorFn} from '../Errors';
import {strLit} from '../StrLit';

export interface PartialThumbnail {
  source: URL;
  width: number;
  height: number;
}

export interface FullThumbnail extends PartialThumbnail {
  metadata: ThumbnailMetadata;
}

export interface ThumbnailMetadata {
  title: string;
  descriptionUrl: URL;
  timestamp: Date;
  user: string;
  description: Maybe<string>;
  credit: Maybe<string>;
  artist: Maybe<string>;
  license: Maybe<string>;
  shortLicense: Maybe<string>;
  licenseUrl: Maybe<URL>;
}

export type ThumbnailMetadatas = ReadonlyArray<ThumbnailMetadata>;

export interface Coordinates {
  lat: number;
  lon: number;
}

interface BaseItem {
  title: string;
  displayTitle: Maybe<string>;
  pageId: Maybe<number>;
  extract: string;
  extractHtml: Maybe<string>;
  lang: string;
  dir: string;
  timestamp: Maybe<Date>;
  description: Maybe<string>;
  coordinates: Maybe<Coordinates>;
}

export interface PartialItem extends BaseItem {
  thumbnail: Maybe<PartialThumbnail>;
}

export interface FullItem extends BaseItem {
  thumbnail: Maybe<FullThumbnail>;
}

const itemStoreBase = (title: string, children: string[]) => ({
  title,
  children,
});

export const waiting = (title: string, children: string[]) => ({
  ...itemStoreBase(title, children),
  kind: strLit('waiting'),
});

export const loadingLocally = (title: string, children: string[]) => ({
  ...itemStoreBase(title, children),
  kind: strLit('loading-locally'),
});

export const loadingRemotely = (title: string, children: string[]) => ({
  ...itemStoreBase(title, children),
  kind: strLit('loading-remotely'),
});

export const loadingThumbnail = (item: PartialItem, children: string[]) => ({
  ...itemStoreBase(item.title, children),
  kind: strLit('loading-thumbnail'),
  item,
});

export const ready = (item: FullItem, children: string[]) => ({
  ...itemStoreBase(item.title, children),
  kind: strLit('ready'),
  item,
});

export const settingChildren = (item: FullItem, children: string[]) => ({
  ...itemStoreBase(item.title, children),
  kind: strLit('setting-children'),
  item,
});

type ItemStoreBase = ReturnType<typeof itemStoreBase>;

export type Waiting = ReturnType<typeof waiting>;

export type LoadingLocally = ReturnType<typeof loadingLocally>;

export type LoadingRemotely = ReturnType<typeof loadingRemotely>;

export type LoadingThumbnail = ReturnType<typeof loadingThumbnail>;

export type Ready = ReturnType<typeof ready>;

export type SettingChildren = ReturnType<typeof settingChildren>;

export type Error = ItemStoreBase & ErrorBase;

export const error = errorFn<Error>('ItemStore');

export type State =
  | Waiting
  | LoadingLocally
  | LoadingRemotely
  | LoadingThumbnail
  | Ready
  | SettingChildren
  | Error;
